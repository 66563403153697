import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';

import { useRouter } from 'next/router';
import { useSessionStorage } from 'react-use';

import { useAnalytics } from '~hooks/useAnalytics';

interface UserContextProps {
  isMittanbud: boolean;
}

const UserContext = createContext<UserContextProps>({ isMittanbud: false });
export const useUser = () => useContext(UserContext);

export const UserContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { query } = useRouter();
  const { identify } = useAnalytics();
  const [isMittanbud, setIsMittanbud] = useSessionStorage('isMittanbud', false);

  useEffect(() => {
    if (!query || Object.keys(query).length === 0) return;
    const { source } = query;

    if (typeof source === 'string' && source === 'mittanbud') setIsMittanbud(true);
  }, [query]);

  const values = useMemo(
    () => ({
      isMittanbud,
    }),
    [isMittanbud]
  );

  useEffect(() => {
    identify({ isMittanbud });
  }, [values]);

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};
