import React from 'react';

import { Div, ButtonLink, Button } from '@schibsted-smb/fireball';
import useTranslation from 'next-translate/useTranslation';

import Logo from '~assets/logo/logo.svg';
import { Paragraph } from '~components/base/Paragraph';
import { Title } from '~components/base/Title';
import { useGdpr } from '~context/GdprContext';
import { LINKS } from '~utils/links';

import { Container } from './Container';
import { StyledTermsLi, StyledTermsUl } from './style';

const termsList = [
  'privacy:introduction.terms.text_1',
  'privacy:introduction.terms.text_2',
  'privacy:introduction.terms.text_3',
  'privacy:introduction.terms.text_4',
  'privacy:introduction.terms.text_5',
];

export const Popup: React.FC = () => {
  const { t } = useTranslation();
  const { acceptAll } = useGdpr();

  return (
    <Container>
      <Div maxWidth={['150px', null, '200px']} width="100%">
        <Logo width="100%" />
      </Div>
      <Div mt={[22, null, null, 8]}>
        <Title.H2 mb={['8px', '16px', null, '24px']} fontSize={[20, 23, 26, 32]}>
          {t('privacy:introduction.header.title')}
        </Title.H2>
        <Paragraph mb={[3, null, 4, 5, 6]} fontWeight="bold" width={[0.8, null, 1]}>
          {t('privacy:introduction.header.subTitle')}
        </Paragraph>
        <Paragraph mb={[5, null, null, 6, null, 25]} fontWeight="normal">
          {t('privacy:introduction.header.text')}
        </Paragraph>
      </Div>

      <Div display={['none', null, null, 'initial']}>
        <Paragraph fontWeight="bold" mb="24px" width={[0.9, 0.75, 1]}>
          {t('privacy:introduction.description.title')}
        </Paragraph>
        <Div ml="24px">
          <StyledTermsUl>
            {termsList.map((number) => (
              <StyledTermsLi key={number.toString()}>
                <Paragraph>{t(number)}</Paragraph>
              </StyledTermsLi>
            ))}
          </StyledTermsUl>
        </Div>
      </Div>

      <Div width={1} mx="auto" pt={[5, null, null, null, 8]} display="flex" justifyContent="center">
        <ButtonLink
          href={LINKS.PRIVACY}
          variant="secondary"
          backgroundColor="primary.lighter"
          color="primary.base"
          target="_blank"
          fontWeight="bold"
          mr="5px"
        >
          {t('privacy:introduction.buttons.2')}
        </ButtonLink>
        <Button variant="primary" fontSize={2} fontWeight="bold" ml="5px" onClick={() => acceptAll()}>
          {t('privacy:introduction.buttons.1')}
        </Button>
      </Div>
    </Container>
  );
};
