import React from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import packageJson from 'package.json';

import { isLocal } from './checks';

const bugsnagOptions = isLocal
  ? { autoDetectErrors: false, autoTrackSessions: false, autoCaptureSessions: false }
  : { autoDetectErrors: true, autoTrackSessions: true, autoCaptureSessions: true };

Bugsnag.start({
  appVersion: packageJson.version || 'missing',
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY || ' ',
  appType: 'nextjs_application',
  metadata: {
    service: 'prosjektpluss-public',
  },
  releaseStage: process.env.NEXT_PUBLIC_BUGSNAG_STAGE || process.env.NEXT_PUBLIC_ENDPOINTS,
  enabledReleaseStages: ['production', 'staging', 'development'],
  plugins: [new BugsnagPluginReact(React)],
  ...bugsnagOptions,
});

export { Bugsnag };
