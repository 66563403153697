
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React from 'react';

import { FireballThemeProvider, prosjektpluss } from '@schibsted-smb/fireball-themes';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { CookiesProvider } from 'react-cookie';

import { XlDialog } from '~components/pages/common/XlDialog';
import { AnalyticsProvider } from '~context/AnalyticsProvider';
import { GdprContextProvider } from '~context/GdprContext';
import { UserContextProvider } from '~context/UserContext';
import { XlFeatureToggleProvider } from '~context/XlFeaturesContext';
import { NextPageWithLayout } from '~types/NextPageWithLayout';
import { Bugsnag } from '~utils/bugsnag';

import '../public/static/style/LiteYoutubeEmbed.css';
import '../public/static/style/SwiperNavigation.css';

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// Only `const __Page_Next_Translate__ = function` because of https://github.com/aralroca/next-translate/issues/486, should be fixed in 2.0 of next-translate
const __Page_Next_Translate__ = function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();

  const isSvarRegistrertRouteWithToken = () => {
    return router.pathname === '/svar-registrert/[...id]' && !!router.query.token;
  };

  const shouldShowDialog = !isSvarRegistrertRouteWithToken();

  return (
    <ErrorBoundary>
      <FireballThemeProvider theme={prosjektpluss}>
        <CookiesProvider>
          <XlFeatureToggleProvider>
            <GdprContextProvider>
              <AnalyticsProvider writeKey={process.env.NEXT_PUBLIC_SEGMENT_KEY}>
                {shouldShowDialog && <XlDialog />}
                <UserContextProvider>{getLayout(<Component {...pageProps} />)}</UserContextProvider>
              </AnalyticsProvider>
            </GdprContextProvider>
          </XlFeatureToggleProvider>
        </CookiesProvider>
      </FireballThemeProvider>
    </ErrorBoundary>
  );
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  