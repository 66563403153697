/* eslint-disable @typescript-eslint/no-var-requires */
const Bugsnag = require('@bugsnag/js').default;

const isDev =
  process.env.NEXT_PUBLIC_ENDPOINTS === 'development' ||
  process.env.ENDPOINTS === 'development' ||
  process.env.NODE_ENV === 'development';

/**
 * @type {import('next-translate').I18nConfig}
 */
module.exports = {
  locales: ['no'],
  defaultLocale: 'no',
  loggerEnvironment: 'both',
  logBuild: false,
  pages: {
    '*': ['common', 'privacy', 'errors'],
    '/404': ['notfound'],
    '/': ['frontpage', 'testimonials'],
    '/aktuelt': ['aktuelt'],
    '/for-bedrifter': ['business', 'frontpage'],
    '/for-borettslag': ['frontpage', 'aktuelt'],
    '/kontakt-oss': ['contact'],
    '/om-oss': ['about'],
    '/samarbeidspartnere': ['partners'],
    '/referanser': ['references'],
    'rgx:/registrer/prosjekt': ['registerProject'],
    '/registrer/bedrift': ['registerBusiness'],
    '/svar-registrert/[...id]': ['matching'],
    '/vilkaar/bedrift': ['terms'],
    '/vilkaar/forbruker': ['terms'],
  },
  // Extende default logger found here https://github.com/aralroca/next-translate/blob/7ebba0c71362b2dd349472d854730c098c793152/src/transCore.tsx#L244
  // to log missing keys to Bugsnag in production.
  logger: ({ namespace, i18nKey }) => {
    // This means that instead of "ns:value", "value" has been misspelled (without namespace)
    if (!namespace) {
      if (isDev) console.error(`"${i18nKey}" has no namespace in front of it.`);

      Bugsnag.notify('Translation key missing namespace', (event) => {
        event.addMetadata('Details', { namespace: 'Missing', key: i18nKey });
      });

      return;
    }

    if (isDev) console.error(`"${namespace}:${i18nKey}" is missing, add "${i18nKey}" to the namespace "${namespace}".`);

    Bugsnag.notify('Missing translation key', (event) => {
      event.addMetadata('Details', { namespace, key: i18nKey });
    });
  },
};
