import { GdprCategories } from '~context/GdprContext/types';

export const categories: GdprCategories[] = [
  {
    name: 'noedvendige-data',
    default: true,
    optional: false,
  },
  {
    name: 'forbedre-opplevelsen',
    default: false,
    optional: true,
  },
  {
    name: 'markedsfoering',
    default: false,
    optional: true,
  },
];

export const text = [
  {
    title: 'Nødvendige data',
    description:
      'Dette er helt nødvendige cookies for at nettsiden skal fungere slik som den skal, disse kan du ikke slå av uten at siden slutter å fungere.\r\n\r\nVi lagrer enkelte cookies for å blant annet å sikre trygg pålogging på tjenesten og hindre forsøk på hackerangrep. I tillegg må vi lagre valgene du gjør av hvilke cookies du godtar og ikke. \r\n\r\nDu kan blokkere disse i nettleseren din, men det vil føre til at nettsiden ikke vil fungere.',
  },
  {
    title: 'Forbedre opplevelsen',
    description:
      'Vi ønsker å ta vare på anonym statistikk for bruken av nettsiden, slik at vi kan gjøre nettsiden bedre for deg. \r\n\r\nDisse cookiene gjør det mulig for oss å se antall besøk, på hvilke sider besøkene kommer og hvilke kilder besøkende kommer fra, slik at vi kan måle og forbedre ytelsen på nettsidene. Et eksempel på dette er at vi kan analysere hvor mange som faller av underveis i registreringen av et oppdrag, slik at vi kan gjøre endringer på registreringsskjemaet og gjøre dette enklere for brukeren. \r\n\r\nAll informasjon blir samlet og aggregert og vil være helt anonym. Med andre ord ser vi ikke på enkeltpersoner, men hvordan en gruppe av besøkende bruker nettstedet. Hvis du ikke ønsker å godkjenne disse cookiene, vil vi ikke vite om du har besøkt nettsiden, og vi vil ikke kunne forbedre tjenesten. \r\n\r\nEn cookie som inkluderer dine innstillinger for forbedret opplevelse, lagres i 12 måneder i denne nettleseren.',
  },
  {
    title: 'Markedsføring',
    description:
      'Vi ønsker å vise anbefalte annonser og innhold for deg basert på anonym bruk av denne nettsiden i Schibsted-nettverket og andre annonsesteder. \r\n\r\nVi kan bruke informasjon om hva du ser på, hvor du befinner deg og hvilken målgruppe du er i for å gjøre reklamen mer relevant for dine interesser på PROSJEKT+ og andre Schibsted-nettsider som VG.no, Aftenposten.no og FINN.no, i tillegg til Google, Facebook m.fl. \r\n\r\nTakker du nei vil du fortsatt få reklame, men den vil være tilfeldig. Schibsted Norge er behandlingsansvarlig for målrettet reklame.',
  },
];
