import React from 'react';

import { Text as FbText, TextProps as FbTextProps } from '@schibsted-smb/fireball';

type TextSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

interface TextProps extends FbTextProps {
  color?: string;
  size?: TextSize;
}

export const defaultFontSize = ['16px', null, null, '18px', null, null, null, '20px'];
export const xsFontSize = ['14px', null, null, '14px', null, null, null, '16px'];
export const sFontSize = ['14px', null, null, '16px', null, null, null, '18px'];
export const lFontSize = ['18px', null, null, '20px', null, null, null, '24px'];
export const xlFontSize = ['18px', null, null, '24px', null, null, null, '40px'];
export const xxlFontSize = ['24px', null, null, '32px', null, null, null, '40px'];

const getSize = (size?: TextSize): string[] | undefined => {
  if (!size) return undefined;

  switch (size) {
    case 'xs':
      return xsFontSize;

    case 's':
      return sFontSize;

    case 'm':
      return defaultFontSize;

    case 'l':
      return lFontSize;

    case 'xl':
      return xlFontSize;

    case 'xxl':
      return xxlFontSize;

    default:
      return defaultFontSize;
  }
};

export const Paragraph: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  fontWeight,
  fontSize,
  color,
  mb,
  size,
  ...rest
}) => (
  <FbText.p
    fontSize={fontSize || getSize(size)}
    fontWeight={fontWeight || 'regular'}
    color={color || 'black.black9'}
    mb={mb || 0}
    {...rest}
  >
    {children}
  </FbText.p>
);
