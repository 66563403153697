import React, { createContext, useEffect, useMemo } from 'react';

import { AnalyticsBrowser } from '@segment/analytics-next';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';

type AnalyticsProviderProps = {
  writeKey: string;
};

export const INFO_DIALOG_INTERACTED = 'cookie-dialog-interacted';

export const AnalyticsContext = createContext<AnalyticsBrowser>(undefined!);

export const AnalyticsProvider: React.FC<React.PropsWithChildren<AnalyticsProviderProps>> = ({
  children,
  writeKey,
}) => {
  const analytics = useMemo(() => new AnalyticsBrowser(), []);
  const router = useRouter();
  const [cookies, setCookie] = useCookies([
    'noedvendige-data',
    'forbedre-opplevelsen',
    'markedsfoering',
    INFO_DIALOG_INTERACTED,
    'debug',
  ]);

  const functionalityCookie: string | undefined = cookies['forbedre-opplevelsen'] as string | undefined;
  const marketingCookie: string | undefined = cookies.markedsfoering as string | undefined;
  const infoDialogInteractedCookie: string | undefined = cookies[INFO_DIALOG_INTERACTED] as string | undefined;

  const CONSENT_REQUIRED = true;
  const CONSENT_FUNCTIONALITY = functionalityCookie === 'true';
  const CONSENT_MARKETING = marketingCookie === 'true';
  const CONSENT_CHECK_COMPLETED = infoDialogInteractedCookie === 'true';

  const integrations = useMemo(
    () => ({
      'Segment.io': CONSENT_REQUIRED,
      Amplitude: CONSENT_FUNCTIONALITY,
      'Actions Amplitude': CONSENT_FUNCTIONALITY,
      Appcues: CONSENT_FUNCTIONALITY,
      'Bing Ads': CONSENT_MARKETING,
      Bugsnag: CONSENT_REQUIRED,
      'Facebook Pixel': CONSENT_MARKETING,
      'Google Analytics 4 Web': CONSENT_MARKETING,
      Hotjar: CONSENT_MARKETING,
      'Intercom Web (Actions)': CONSENT_FUNCTIONALITY,
      Intercom: CONSENT_FUNCTIONALITY,
      'Visual Website Optimizer': CONSENT_FUNCTIONALITY,
      Webhooks: CONSENT_FUNCTIONALITY,
    }),
    [CONSENT_REQUIRED, CONSENT_FUNCTIONALITY, CONSENT_MARKETING]
  );

  useEffect(() => {
    if (!CONSENT_CHECK_COMPLETED || !router.isReady) return;
    analytics.load({ writeKey }, { integrations });
  }, [writeKey, router.isReady, CONSENT_CHECK_COMPLETED]);

  useEffect(() => {
    if (!router.isReady) return;
    if (router.query.debug) setCookie('debug', `${router.query.debug === 'true'}`);
  }, [router.isReady, router.query]);

  analytics.debug(cookies.debug === 'true');

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};
