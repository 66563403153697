import styled from 'styled-components';

interface SpanProps {
  color?: string;
  fontWeight?: string;
  display?: string;
}

export const Span = styled.span<SpanProps>`
  display: ${(props) => (props.display ? props.display : 'inline')};
  color: ${(props) => (props.color ? props.color : null)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : null)};
`;
