import { createGlobalStyle } from 'styled-components';

const MaFontStyles = createGlobalStyle`

  @font-face {
    font-family: 'EuclidFireball';
    src: url('https://cdn.schibstedsmb.no/fonts/EuclidFireball/WOFF2/EuclidFireball-Bold-WebXL.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'EuclidFireball';
    src: url('https://cdn.schibstedsmb.no/fonts/EuclidFireball/WOFF2/EuclidFireball-Semibold-WebXL.woff2') format('woff2');
    font-style: semiBold;
    font-weight: 600;
    font-display: fallback;
  }
  @font-face {
    font-family: 'EuclidFireball';
    src: url('https://cdn.schibstedsmb.no/fonts/EuclidFireball/WOFF2/EuclidFireball-Medium-WebXL.woff2') format('woff2');
    font-style: medium;
    font-weight: 500;
    font-display: fallback;
  }
  @font-face {
    font-family: 'EuclidFireball';
    src: url('https://cdn.schibstedsmb.no/fonts/EuclidFireball/WOFF2/EuclidFireball-Regular-WebXL.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
  }
`;

export default MaFontStyles;
