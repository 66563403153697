import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

import { IPrivacyContainerStyles } from './interface';

export const PrivacyContainer = styled(Div)<IPrivacyContainerStyles>`
  position: relative;
  display: flex;
  align-items: ${({ center }) => (center ? 'center' : 'start')};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  flex-direction: column;
  background-color: #fff;
`;
