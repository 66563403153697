export const LINKS = {
  HOME: '/',

  ABOUT: '/om-oss',

  PROJECT_GUIDES: '/prosjektveiledere',

  REFERENCES: '/referanser',

  ASSOCIATION: '/for-borettslag',

  FOR_BUSINESSES: '/for-bedrifter',

  BUSINESS_PROFILE: '/c/bedrifter',

  ARTICLES: '/aktuelt',

  CONTACT: '/kontakt-oss',

  REGISTER: {
    PROJECT: {
      START: '/registrer/prosjekt',
      REGISTERED: '/registrer/prosjekt/registrert',
      COMPLETE: '/registrer/prosjekt/fullfort',
    },
    BUSINESS: {
      START: '/registrer/bedrift',
      COMPLETE: '/registrer/bedrift/fullfort',
    },
  },

  QUALITY_ASSURANCE: '/c/om/kvalitetssikring-og-sertifiseringer',

  PROJECT_HANDLING: '/c/prosjekter/prosjektstyring',

  ARCHITECT: '/c/prosjekter/arkitekt',

  SUSTAINABILITY: '/c/prosjekter/energi-og-baerekraft',

  PRIVACY: '/personvern',
  PRIVACY_POLICY: '/c/om/personvernerklaering',

  PARTNERS: '/samarbeidspartnere',

  WEBINAR: '/webinar',

  /**
  Remember to add your parameters after path
  */
  ANSWER_REGISTERED: '/svar-registrert',

  TERMS: {
    CONSUMER: '/vilkaar/forbruker',
    BUSINESS: '/vilkaar/bedrift',
  },

  SOCIAL: {
    FACEBOOK: 'https://facebook.com/prosjektpluss',
    INSTAGRAM: 'https://instagram.com/prosjektpluss',
    LINKEDIN: 'https://linkedin.com/company/prosjektpluss',
    YOUTUBE: 'https://youtube.com/channel/UCQ23KR2YRwqhggFDQAMLVjg',
  },

  MAIL: {
    CONTACT: 'mailto:info@prosjektpluss.no',
    PRODUCT_OWNER: 'mailto:info@prosjektpluss.no',
  },

  EXTERNAL: {
    MITTANBUD: 'https://mittanbud.no',
  },
};
