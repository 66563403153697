import React, { useEffect } from 'react';

import { Div, Link, Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';
import { space, SpaceProps, position, PositionProps, layout, LayoutProps } from 'styled-system';

import { Paper } from '~assets/images/Paper';
import Logo from '~assets/logo/logo.svg';
import { MaxlLogo } from '~assets/logo/MaxlLogo';
import { Paragraph } from '~components/base/Paragraph';
import { Title } from '~components/base/Title';
import { useXlFeatureToggle } from '~context/XlFeaturesContext';
import { useWindowSize } from '~hooks/useWindowSize';
import { isDev } from '~utils/checks';
import MaFontStyles from '~utils/maFontStyles';

import { StyledMaButton } from './style';

interface IDialogProps extends SpaceProps, PositionProps, LayoutProps {
  scrollable?: boolean;
}
export const StyledDialog = styled.dialog<IDialogProps>`
  ${space};
  ${position};
  ${layout};
  background-color: #fbf6f1;
  border: none;
  box-shadow: none;
  padding: 0;
  z-index: 99999;
  overflow-y: ${({ scrollable }) => (scrollable ? 'scroll' : 'auto')};
`;
export const StyledBackdrop = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  backdrop-filter: blur(10px);
`;

export const XlDialog: React.FC = () => {
  const { isXlEnabled } = useXlFeatureToggle();
  const isSmall = useWindowSize().width < 541;
  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);
  if (!isXlEnabled) {
    return null;
  }
  return (
    <>
      <MaFontStyles />
      <StyledBackdrop />
      <StyledDialog
        position={['absolute', null, null, null, 'fixed']}
        width={[1, null, null, null, 640]}
        height={['100svh', null, null, null, 790]}
        marginLeft={[0, null, null, null, -320]}
        marginTop={[0, null, null, null, -370]}
        top={['0px', null, null, null, '50%']}
        left={[0, null, null, null, '50%']}
        open
        scrollable={isSmall}
      >
        <Div
          px={[5, null, null, null, null, 115]}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          backgroundColor="#FFF"
          textAlign="center"
        >
          <Div mb={20} mt={30} width={186}>
            <Logo />
          </Div>
          <Title.H4 fontSize={32} mb={20}>
            Spennende oppdatering!
          </Title.H4>
          <Paragraph textAlign="center">
            PROSJEKT+ slår seg sammen med Mittanbud og skifter navn til Mittanbud XL!
          </Paragraph>
        </Div>
        <Div>
          <Paper width="100%" height={isSmall ? '100%' : '66'} />
        </Div>
        <Div display="flex" flexDirection="column" px={[5, null, null, null, null, 30]} fontFamily="EuclidFireball">
          <Div mx="auto">
            <MaxlLogo width={220} height={40} />
          </Div>
          <Paragraph fontSize={16} mt={20}>
            PROSJEKT+ slår seg sammen med Mittanbud og skifter navn til Mittanbud XL. Du vil fortsatt kunne nyte alle de
            kjente tjenestene og funksjonene du er vant til, men nå som en del av Mittanbud.
          </Paragraph>
          <Title.H4 fontSize={16} mt={20} fontWeight={500}>
            Hva betyr dette for meg?
          </Title.H4>
          <Paragraph fontSize={16} mt={1}>
            Alt vil fungere som før, med tillegg av nye fordeler fra Mittanbud. Vi ser frem til å gi deg en bedre
            tjeneste!
          </Paragraph>
          <Title.H4 fontSize={16} mt={20} fontWeight={500}>
            Spørsmål?
          </Title.H4>
          <Paragraph fontSize={16} mt={1}>
            Kontakt oss på xl@mittanbud.no eller{' '}
            <span>
              <Link
                color="#3960BF"
                href={
                  isDev
                    ? 'https://ma-no-dev.click/privatperson/startguide/mittanbudxl'
                    : 'https://mittanbud.no/privatperson/startguide/mittanbudxl'
                }
              >
                les mer her.
              </Link>
            </span>
          </Paragraph>
          <Paragraph fontSize={16} mt={20}>
            Med vennlig hilsen,
          </Paragraph>
          <Paragraph fontSize={16} mt={1}>
            PROSJEKT+
          </Paragraph>
          <Div
            mt={30}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={[1, null, null, null, null, 0.8]}
            mx="auto"
          >
            <Paragraph fontSize={16} mb={20}>
              Fortsett til Mittanbud XL, og legg ut ditt prosjekt her!
            </Paragraph>
            <Link
              variant="secondary"
              style={{ textDecoration: 'none' }}
              href={isDev ? 'https://ma-no-dev.click/legg-ut' : 'https://mittanbud.no/legg-ut'}
            >
              <StyledMaButton>Fortsett til Mittanbud XL</StyledMaButton>
            </Link>
          </Div>
        </Div>
      </StyledDialog>
    </>
  );
};
