import { createContext, useContext, useState, ReactNode, useMemo } from 'react';

interface XlFeatureToggleContextType {
  isXlEnabled: boolean;
}

const FeatureToggleContext = createContext<XlFeatureToggleContextType | undefined>(undefined);

export const useXlFeatureToggle = (): XlFeatureToggleContextType => {
  const context = useContext(FeatureToggleContext);
  if (!context) {
    throw new Error('useXlFeatureToggle must be used within a XlFeatureToggleProvider');
  }
  return context;
};

interface XlFeatureToggleProviderProps {
  children: ReactNode;
}

export const XlFeatureToggleProvider: React.FC<XlFeatureToggleProviderProps> = ({ children }) => {
  const [isXlEnabled] = useState<boolean>(true);

  const contextValue = useMemo(() => ({ isXlEnabled }), [isXlEnabled]);

  return <FeatureToggleContext.Provider value={contextValue}>{children}</FeatureToggleContext.Provider>;
};
