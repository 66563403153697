import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { Div } from '@schibsted-smb/fireball';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { useLockBodyScroll } from 'react-use';
import styled from 'styled-components';

import { categories } from '~components/pages/personvern/gdpr';
import { Popup } from '~components/pages/personvern/Popup';

import { INFO_DIALOG_INTERACTED } from '../AnalyticsProvider';
import { GdprContextProps, CookieNames } from './types';

const Backdrop = styled(Div)`
  background-color: rgba(0, 0, 0, 0.5);
`;

const GdprState: GdprContextProps = {
  acceptAll: () => {},
  toggleOption: () => {},
};

export const GdprContext = createContext<GdprContextProps>(GdprState);
export const useGdpr = () => useContext(GdprContext);

export const GdprContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const cookieOptions = { expires: dayjs().add(1, 'month').toDate() };

  const [isReady, setIsReady] = useState(false); // Router.isReady returns true on server, which causes a hydration error
  const isDisabled = true; // Flag to disable cookie consent for maxl transition
  const { pathname } = useRouter();

  const [cookies, setCookie] = useCookies<CookieNames, { [key in CookieNames]: boolean }>([
    'noedvendige-data',
    'forbedre-opplevelsen',
    'markedsfoering',
    INFO_DIALOG_INTERACTED,
  ]);

  const [selected, setSelected] = useState({
    'noedvendige-data': cookies['noedvendige-data'],
    'forbedre-opplevelsen': cookies['forbedre-opplevelsen'],
    markedsfoering: cookies.markedsfoering,
    [INFO_DIALOG_INTERACTED]: cookies['cookie-dialog-interacted'],
  });

  const isPrivacyPage = useMemo(() => pathname === '/personvern', [pathname]);

  useLockBodyScroll(!cookies[INFO_DIALOG_INTERACTED] && isReady && !isPrivacyPage);

  const updateItem = (name: CookieNames, value: boolean, interactedValue = true): void => {
    setCookie(name, value, cookieOptions);
    setCookie(INFO_DIALOG_INTERACTED, interactedValue, cookieOptions);
    setSelected((currentValues) => ({
      ...currentValues,
      [name]: value,
      [INFO_DIALOG_INTERACTED]: interactedValue,
    }));
  };

  const toggleOption = (name: CookieNames) => updateItem(name, !selected[name]);

  const acceptAll = () => Object.keys(cookies).forEach((cookie) => updateItem(cookie as CookieNames, true));

  const value = useMemo(() => ({ acceptAll, selected, toggleOption }), [selected]);

  useEffect(() => {
    categories.forEach((cookie) => {
      // Set defaults if cookie has no value, if a cookie is missing a value we toggle modal
      if (!cookies[cookie.name]) updateItem(cookie.name, cookie.default, false);
    });

    setIsReady(true);
  }, []);

  return (
    <GdprContext.Provider value={value}>
      <>
        {!selected[INFO_DIALOG_INTERACTED] && isReady && !isPrivacyPage && !isDisabled && (
          <Backdrop
            width="100%"
            height="100%"
            position="absolute"
            zIndex={10000}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Div width="100%" maxWidth={714} height="max-content">
              <Popup />
            </Div>
          </Backdrop>
        )}
        {children}
      </>
    </GdprContext.Provider>
  );
};
