import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const StyledMaButton = styled(Button)`
  background-color: #3960bf;
  &:hover {
    background-color: #294fab;
    color: #fff;
  }
`;
