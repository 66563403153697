import { useContext, useEffect } from 'react';

import { EventProperties, Options } from '@segment/analytics-next';
import { useRouter } from 'next/router';

import { AnalyticsContext } from '~context/AnalyticsProvider';

export const useAnalytics = () => {
  const result = useContext(AnalyticsContext);

  if (!result) throw new Error('Context used outside of its Provider!');

  return result;
};

const isBrowser = typeof window !== 'undefined';

export const usePageEvent = (
  name: string,
  properties?: EventProperties,
  options?: Options,
  callback?: () => void
): void => {
  const { page } = useAnalytics();
  const { isReady } = useRouter();

  useEffect(() => {
    if (!isReady || !isBrowser) return;
    page(undefined, name, properties, options, callback);
  }, [isReady, isBrowser]);
};

export const useTrackEvent = (
  event: string,
  properties?: EventProperties,
  options?: Options,
  callback?: () => void
): void => {
  const { track } = useAnalytics();
  const { isReady } = useRouter();

  useEffect(() => {
    if (!isReady) return;

    track(event, properties, options, callback);
  }, [isReady]);
};
