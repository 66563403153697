import styled from 'styled-components';

export const StyledTermsUl = styled.ul`
  list-style-type: disc;
  margin-left: 0;
  padding-left: 0;
  margin-right: 0;
`;

export const StyledTermsLi = styled.li`
  margin-bottom: 16px;
  margin-right: 0;
  font-weight: 500;
  ::marker {
    color: #5770c8;
    font-size: x-large;
  }

  :last-child {
    margin-bottom: 0;
  }
`;
